import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import SlideToggle from '../ReactSlideToggle/ReactSlideToggle.umd'
import dynamic from 'next/dynamic';
import { getAnchorIdFromEntity, useAnchor } from '../../adapters/helpers/Anchor';
import { toggleScroll } from '../../adapters/helpers/Utils';
import Block from '../../adapters/helpers/Block';
import PropTypes from 'prop-types';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks'
import { LanguageSelectionConstants, MainMenuConstants } from '../../adapters/helpers/Constants'
import { Label } from '../Label/Label'
import { AnalyticsConstants } from '../../adapters/helpers/ConstantsGA';
import Link from 'next/link';
import Loader from '../../adapters/helpers/Loader';
const Icon = dynamic(() => import('../Icon/Icon'));

const primaryColor = LanguageSelectionConstants.primaryColor;

function LanguageSelection(props) {
    const block = new Block(props);
    const anchor = useAnchor();
    const overlayToggleRef = useRef();
    const languageSelectorRef = useRef();
    const linksRef = useRef();
    const [isLanguageSelectorOpen, setLanguageSelectorOpen] = useState(false);
    const [lastScroll, setLastScroll] = useState(0);
    const handleExpanding = useCallback(
        () => { setLanguageSelectorOpen(true); },
        []
    );
    const handleCollapsing = useCallback(
        () => { setLanguageSelectorOpen(false); },
        []
    );
    const handleClickOutside = useCallback(
        (event) => {
            if (overlayToggleRef.current && languageSelectorRef.current && !languageSelectorRef.current.contains(event.target)) {
                overlayToggleRef.current.onToggle();
            }
        },
        []
    );
    const handleScroll = useCallback(
        () => {
            if (!document.body.classList.contains('noScroll')) {
                setLastScroll(window.pageYOffset);
            }
        }
    );
    useEffect(
        () => {
            if (isLanguageSelectorOpen) {
                window.addEventListener('mousedown', handleClickOutside);
                toggleScroll(isLanguageSelectorOpen, lastScroll);

                return () => {
                    window.removeEventListener('mousedown', handleClickOutside);
                }
            } else {
                toggleScroll(isLanguageSelectorOpen, lastScroll);
            }
        },
        [isLanguageSelectorOpen]
    );

    useEffect(
        () => {
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            }
        },
        [lastScroll]
    );

    const scrollTo = (event) => {
        event.preventDefault();
        if (languageSelectorRef.current) {
            const currentTarget = event.currentTarget;
            const anchorId = currentTarget.dataset.anchorId
            const element = languageSelectorRef.current.querySelector(`[data-id=${anchorId}]`);
            element.scrollIntoView({ behavior: LanguageSelectionConstants.smooth });
        }
    };

    const calculateHeight = () => {
        const announcementBar = document.querySelector('.ob-announcement-bar');
        const overlay = languageSelectorRef.current.querySelector('.ob-language-overlay');
        const header = document.querySelector('.zone-header');
        if (props.isMedium) {
            if (announcementBar && announcementBar.style.display !== 'none') {
                linksRef.current.style.height = `calc(${window.innerHeight}px - ${linksRef.current.offsetTop + announcementBar.offsetHeight}px)`
                overlay.style.height = `calc(${window.innerHeight}px - ${overlay.offsetTop + announcementBar.offsetHeight}px)`
            } else {
                linksRef.current.style.height = `calc(${window.innerHeight}px - ${linksRef.current.offsetTop}px)`
                overlay.style.height = `calc(${window.innerHeight}px - ${overlay.offsetTop}px)`
            }
        } else {
            if (header) {
                overlay.style.height = `calc(${window.innerHeight}px - ${overlay.offsetTop}px)`;
                linksRef.current.style.height = `${window.innerHeight - (linksRef.current.offsetTop + header.offsetHeight)}px`
            }
        }
    };

    // This is the label displayed and also serves as the CTA to trigger the language menu
    const currentLanguage = block.getFieldValue(LanguageSelectionConstants.currentLanguage);
    const title = block.getFieldValue(LanguageSelectionConstants.title);
    const regions = block.getFieldValue(LanguageSelectionConstants.regions);
    const jumpToCopy = Label({ label: block.getFieldValue(LanguageSelectionConstants.jumpToLabel) });
    const closeButtonCopy = Label({ label: block.getFieldValue(LanguageSelectionConstants.closeLanguageSelectionLabel) });

    return (
        <Suspense fallback={<Loader />}>
            <SlideToggle
                ref={overlayToggleRef}
                duration={500}
                collapsed
                onExpanding={handleExpanding}
                onCollapsing={handleCollapsing}
                onExpanded={calculateHeight}
            >
                {({ onToggle, setCollapsibleElement, toggleState }) => (
                    <div className='ob-language-selector' ref={languageSelectorRef} onKeyDown={(event) => {
                        if (event.key === MainMenuConstants.capEscape && (toggleState === MainMenuConstants.capExpanded || toggleState === MainMenuConstants.capExpanding)) {
                            onToggle();
                        }
                    }}>
                        <button className='event_button_click ob-language-selector-toggle'
                            aria-label={`${currentLanguage}, ${MainMenuConstants.pullDown} ${isLanguageSelectorOpen ? MainMenuConstants.expanded : MainMenuConstants.collapsed}`}
                            data-action-detail={AnalyticsConstants.countrySelectorText} onClick={() => {
                                onToggle();
                                calculateHeight();
                            }}>
                            {currentLanguage}
                            <Icon className='ob-language-selector-toggle-icon' name={LanguageSelectionConstants.wideChevronDown} size='0.8' arialable={MainMenuConstants.downArrow} />
                        </button>
                        <div className='language-overlay ob-language-overlay'
                            ref={setCollapsibleElement}
                            style={{ display: 'none' }}
                            role={MainMenuConstants.menuitem}
                        >
                            <div className='ob-language-overlay-inner' role={MainMenuConstants.menubar}>
                                <div className='ob-language-overlay-header' role={MainMenuConstants.menuitem}>
                                    <span className='ob-language-overlay-title'>{title}</span>
                                </div>
                                <div role={MainMenuConstants.menubar} className='ob-language-overlay-tabs' aria-label={MainMenuConstants.menulist}>
                                    <div role={MainMenuConstants.menuitem} className='ob-language-overlay-tabs-desktop' aria-label={MainMenuConstants.menulistDesktop}>
                                        <ul className='mini-nav-regions ob-language-overlay-tabs'>
                                            {regions.map((region, index) => (
                                                <li key={`region-nav-${index}`}>
                                                    <Link aria-label={region.fields.name} className='event_internal_link'
                                                        data-action-detail={getAnchorIdFromEntity(region)}
                                                        href={`#${getAnchorIdFromEntity(region)}-${index}`}
                                                        data-anchor-id={getAnchorIdFromEntity(region)}
                                                        onClick={scrollTo}
                                                    >
                                                        {region.fields.name}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div role={MainMenuConstants.menubar} aria-label={MainMenuConstants.languageLink} className='ob-language-overlay-links' ref={linksRef}>
                                    <div role={MainMenuConstants.menuitem} aria-label={MainMenuConstants.languageLink} className='ob-language-overlay-tabs'>
                                        <SlideToggle duration={500} collapsed>
                                            {({ onToggle, setCollapsibleElement, toggleState }) => (
                                                <div aria-label={MainMenuConstants.languageTabMobile} className='ob-language-overlay-tabs-mobile'>
                                                    <button aria-expanded={toggleState === MainMenuConstants.capExpanded || toggleState === MainMenuConstants.capExpanding ? true : false} className='ob-language-overlay-tabs-toggle' aria-label={MainMenuConstants.expandingMenu} onClick={onToggle}>
                                                        {jumpToCopy}
                                                        <span className='ob-language-overlay-tabs-toggle--icon'>
                                                            {toggleState === MainMenuConstants.capExpanded || toggleState === MainMenuConstants.capExpanding ? (
                                                                <Icon name={MainMenuConstants.chevronTop} color={MainMenuConstants.white} size={2} />
                                                            ) : (
                                                                <Icon name={MainMenuConstants.chevronDown} color={MainMenuConstants.white} size={2} />
                                                            )}
                                                        </span>
                                                    </button>
                                                    <div ref={setCollapsibleElement} role={MainMenuConstants.menuitem}
                                                        className='ob-language-overlay-tabs-mobile-inner'
                                                        style={{ display: 'none' }}>
                                                        <ul className='mini-nav-regions'>
                                                            {regions.map((region, index) => (
                                                                <li key={'region-nav-' + index}>
                                                                    <Link aria-label={region.fields.name} className='event_internal_link'
                                                                        data-action-detail={getAnchorIdFromEntity(region)}
                                                                        href={`#${getAnchorIdFromEntity(region)}-${index}`}
                                                                        data-anchor-id={getAnchorIdFromEntity(region)}
                                                                        onClick={scrollTo}
                                                                    >
                                                                        {region.fields.name}
                                                                    </Link>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}
                                        </SlideToggle>
                                    </div>
                                    <ul className='market-selection'>
                                        {regions.map((region, regionIndex) => (
                                            <li key={'region-' + regionIndex} className="market-selection-title" data-id={getAnchorIdFromEntity(region)} id={`${getAnchorIdFromEntity(region)}-${regionIndex}`}>
                                                {region.fields.name}
                                                <ul>
                                                    {region.fields.markets.map((market, marketIndex) => (
                                                        <li key={'market-' + marketIndex}>
                                                            <Link aria-label={market.fields.nativeLanguageLabel} className='event_internal_link'
                                                                data-action-detail={market.fields.nativeLanguageLabel}
                                                                href={market.fields.link}
                                                                lang={market.fields.languageCode}>
                                                                {market.fields.nativeLanguageLabel}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <button className='event_close_window ob-language-overlay-close-btn' onClick={onToggle} aria-label={closeButtonCopy}>
                                    <Icon name={LanguageSelectionConstants.close} size='2.4' color={primaryColor} arialable={closeButtonCopy} />
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </SlideToggle>
        </Suspense>
    );
}

export default mediaQueryHOC(LanguageSelection)
// This export is for unit testing (do not remove) :
export const LanguageSelectionTest = LanguageSelection;

LanguageSelection.propTypes = {
    isMedium: PropTypes.bool,
};
